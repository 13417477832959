"use strict";

import Stickyfill from 'stickyfilljs'
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/toast';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import flatpickr from "flatpickr";
import('flatpickr/dist/flatpickr.css');
import { english } from "flatpickr/dist/l10n/default.js"
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw.js"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'owl.carousel';
require("select2");
require("jquery.fancybox")
require("datatables.net")
require('datatables.net-bs4')
// require("datatables.net-select-dt")
require("datatables.net-responsive")
require("datatables.net-responsive-bs4")
import "toastr/toastr.scss"
require("toastr")
// import 'datatables.net'
// var $ = require( 'jquery' );
// require( 'datatables.net' )( window, $ );
// import 'datatables.net-bs4/css/dataTables.bootstrap4.min';
// require( 'datatables.net-bs4' )
// import { formreviewdata } from "packs/application_form_helper"


// require('twitter-bootstrap-wizard')
import moment from "moment"
import "dropzone/dist/dropzone.css"

const { Dropzone } = require("dropzone");


if (document.documentElement.lang === "zh-HK") {
  require("jquery-validation/dist/localization/messages_zh_TW")
}

var _this = this;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*-----------------------------------------------
|   Theme Configuration
-----------------------------------------------*/
var storage = {
  isDark: false
};
/*-----------------------------------------------
|   Utilities
-----------------------------------------------*/

var utils = function ($) {
  var grays = function grays() {
    var colors = {
      white: '#fff',
      100: '#f9fafd',
      200: '#edf2f9',
      300: '#d8e2ef',
      400: '#b6c1d2',
      500: '#9da9bb',
      600: '#748194',
      700: '#5e6e82',
      800: '#4d5969',
      900: '#344050',
      1000: '#232e3c',
      1100: '#0b1727',
      black: '#000'
    };

    if (storage.isDark) {
      colors = {
        white: '#0e1c2f',
        100: '#132238',
        200: '#061325',
        300: '#344050',
        400: '#4d5969',
        500: '#5e6e82',
        600: '#748194',
        700: '#9da9bb',
        800: '#b6c1d2',
        900: '#d8e2ef',
        1000: '#edf2f9',
        1100: '#f9fafd',
        black: '#fff'
      };
    }

    return colors;
  };

  var themeColors = function themeColors() {
    var colors = {
      primary: '#2c7be5',
      secondary: '#748194',
      success: '#00d27a',
      info: '#27bcfd',
      warning: '#f5803e',
      danger: '#e63757',
      light: '#f9fafd',
      dark: '#0b1727'
    };

    if (storage.isDark) {
      colors.light = grays()['100'];
      colors.dark = grays()['1100'];
    }

    return colors;
  };

  var pluginSettings = function pluginSettings() {
    var settings = {
      tinymce: {
        theme: 'oxide'
      },
      chart: {
        borderColor: 'rgba(255, 255, 255, 0.8)'
      }
    };

    if (storage.isDark) {
      settings.tinymce.theme = 'oxide-dark';
      settings.chart.borderColor = themeColors().primary;
    }

    return settings;
  };

  var Utils = {
    $window: $(window),
    $document: $(document),
    $html: $('html'),
    $body: $('body'),
    $main: $('main'),
    isRTL: function isRTL() {
      return this.$html.attr('dir') === 'rtl';
    },
    location: window.location,
    nua: navigator.userAgent,
    breakpoints: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1540
    },
    colors: themeColors(),
    grays: grays(),
    offset: function offset(element) {
      var rect = element.getBoundingClientRect();
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
      };
    },
    isScrolledIntoViewJS: function isScrolledIntoViewJS(element) {
      var windowHeight = window.innerHeight;
      var elemTop = this.offset(element).top;
      var elemHeight = element.offsetHeight;
      var windowScrollTop = window.scrollY;
      return elemTop <= windowScrollTop + windowHeight && windowScrollTop <= elemTop + elemHeight;
    },
    isScrolledIntoView: function isScrolledIntoView(el) {
      var $el = $(el);
      var windowHeight = this.$window.height();
      var elemTop = $el.offset().top;
      var elemHeight = $el.height();
      var windowScrollTop = this.$window.scrollTop();
      return elemTop <= windowScrollTop + windowHeight && windowScrollTop <= elemTop + elemHeight;
    },
    getCurrentScreanBreakpoint: function getCurrentScreanBreakpoint() {
      var _this2 = this;

      var currentScrean = '';
      var windowWidth = this.$window.width();
      $.each(this.breakpoints, function (index, value) {
        if (windowWidth >= value) {
          currentScrean = index;
        } else if (windowWidth >= _this2.breakpoints.xl) {
          currentScrean = 'xl';
        }
      });
      return {
        currentScrean: currentScrean,
        currentBreakpoint: this.breakpoints[currentScrean]
      };
    },
    hexToRgb: function hexToRgb(hexValue) {
      var hex;
      hexValue.indexOf('#') === 0 ? hex = hexValue.substring(1) : hex = hexValue; // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")

      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      }));
      return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
    },
    rgbColor: function rgbColor(color) {
      if (color === void 0) {
        color = '#fff';
      }

      return "rgb(" + this.hexToRgb(color) + ")";
    },
    rgbaColor: function rgbaColor(color, alpha) {
      if (color === void 0) {
        color = '#fff';
      }

      if (alpha === void 0) {
        alpha = 0.5;
      }

      return "rgba(" + this.hexToRgb(color) + ", " + alpha + ")";
    },
    rgbColors: function rgbColors() {
      var _this3 = this;

      return Object.keys(this.colors).map(function (color) {
        return _this3.rgbColor(_this3.colors[color]);
      });
    },
    rgbaColors: function rgbaColors() {
      var _this4 = this;

      return Object.keys(this.colors).map(function (color) {
        return _this4.rgbaColor(_this4.colors[color]);
      });
    },
    settings: pluginSettings(_this),
    isIterableArray: function isIterableArray(array) {
      return Array.isArray(array) && !!array.length;
    },
    setCookie: function setCookie(name, value, expire) {
      var expires = new Date();
      expires.setTime(expires.getTime() + expire);
      document.cookie = name + "=" + value + ";expires=" + expires.toUTCString();
    },
    getCookie: function getCookie(name) {
      var keyValue = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
      return keyValue ? keyValue[2] : keyValue;
    },
    getBreakpoint: function getBreakpoint($element) {
      var classes = $element.attr('class');
      var breakpoint;

      if (classes) {
        breakpoint = this.breakpoints[classes.split(' ').filter(function (cls) {
          return cls.indexOf('navbar-expand-') === 0;
        }).pop().split('-').pop()];
      }

      return breakpoint;
    }
  };
  return Utils;
}(jQuery);
/*-----------------------------------------------
|   Detector
-----------------------------------------------*/


document.addEventListener("turbolinks:load", function() {
  if (window.is.opera()) utils.$html.addClass('opera');
  if (window.is.mobile()) utils.$html.addClass('mobile');
  if (window.is.firefox()) utils.$html.addClass('firefox');
  if (window.is.safari()) utils.$html.addClass('safari');
  if (window.is.ios()) utils.$html.addClass('ios');
  if (window.is.iphone()) utils.$html.addClass('iphone');
  if (window.is.ipad()) utils.$html.addClass('ipad');
  if (window.is.ie()) utils.$html.addClass('ie');
  if (window.is.edge()) utils.$html.addClass('edge');
  if (window.is.chrome()) utils.$html.addClass('chrome');
  if (utils.nua.match(/puppeteer/i)) utils.$html.addClass('puppeteer');
  if (window.is.mac()) utils.$html.addClass('osx');
  if (window.is.windows()) utils.$html.addClass('windows');
  if (navigator.userAgent.match('CriOS')) utils.$html.addClass('chrome');
});
/*-----------------------------------------------
|   Emoji Picker
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Event = {
    FOCUS: 'focus'
  };
  var Selector = {
    EMOJIAREA: '.emojiarea'
  };
  var DATA_KEY = {
    OPTIONS: 'options'
  };
  var emojioneareas = $(Selector.EMOJIAREA); // Place the Blinking Text Cursor at the end of the editor text

  var placeCaretAtEnd = function placeCaretAtEnd(el) {
    if (!!window.getSelection && !!document.createRange) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.body.createTextRange) {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  }; // EmojioneArea plugin call


  if (emojioneareas.length) {
    emojioneareas.each(function (item, value) {
      var $this = $(value);
      var options = $.extend({}, $this.data(DATA_KEY.OPTIONS));
      $this.emojioneArea(options); // // Call the caret position function on focus

      emojioneareas[item].emojioneArea.on(Event.FOCUS, function ($editor) {
        placeCaretAtEnd($editor.get(0));
      });
    });
  }
});
/*-----------------------------------------------
|   Animated progressbar
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var toggle = $('#progress-toggle-animation');
  toggle.on('click', function () {
    return $('#progress-toggle').toggleClass('progress-bar-animated');
  });
});
/*-----------------------------------------------
|   Top navigation opacity on scroll
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $navbar = $('.navbar-theme');

  if ($navbar.length) {
    var windowHeight = utils.$window.height();
    utils.$window.scroll(function () {
      var scrollTop = utils.$window.scrollTop();
      var alpha = scrollTop / windowHeight * 2;
      alpha >= 1 && (alpha = 1);
      $navbar.css({
        'background-color': "rgba(255, 255, 255, " + alpha + ")"
      });
    }); // Fix navbar background color [after and before expand]

    var classList = $navbar.attr('class').split(' ');
    var breakpoint = classList.filter(function (c) {
      return c.indexOf('navbar-expand-') >= 0;
    })[0].split('navbar-expand-')[1];
    utils.$window.resize(function () {
      if (utils.$window.width() > utils.breakpoints[breakpoint]) {
        return $navbar.removeClass('bg-dark');
      }

      if (!$navbar.find('.navbar-toggler').hasClass('collapsed')) {
        return $navbar.addClass('bg-dark');
      }

      return null;
    }); // Top navigation background toggle on mobile

    $navbar.on('show.bs.collapse hide.bs.collapse', function (e) {
      $(e.currentTarget).toggleClass('bg-dark');
    });
  }
});
/*-----------------------------------------------
|   Select menu [bootstrap 4]
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  // https://getbootstrap.com/docs/4.0/getting-started/browsers-devices/#select-menu
  // https://github.com/twbs/bootstrap/issues/26183
  window.is.android() && $('select.form-control').removeClass('form-control').css('width', '100%');
});
// /*-----------------------------------------------
// |   Bootstrap Wizard
// -----------------------------------------------*/

// document.addEventListener("turbolinks:load", function() {
//   var Selector = {
//     DATA_WIZARD: '[data-wizard]',
//     PREVIOUS_BUTTON: '.previous .btn',
//     TAB_PANE: '.tab-pane',
//     FORM_VALIDATION: '.form-validation',
//     NAV_ITEM_CIRCLE: '.nav-item-circle',
//     NAV_ITEM: '.nav-item',
//     NAV_LINK: '.nav-link',
//     WIZARD_LOTTIE: '.wizard-lottie'
//   };
//   var ClassName = {
//     ACTIVE: 'active',
//     DONE: 'done',
//     NAV: 'nav'
//   };
//   var DATA_KEY = {
//     OPTIONS: 'options',
//     WIZARD_STATE: 'wizard-state',
//     CONTROLLER: 'controller',
//     ERROR_MODAL: 'error-modal'
//   };
//   var wizards = $(Selector.DATA_WIZARD);

//   var isFormValidate = function isFormValidate($currentTab) {
//     var $currentTabForms = $currentTab.find(Selector.FORM_VALIDATION);
//     var isValidate = true;
//     $currentTabForms.each(function (i, v) {
//       isValidate = $(v).valid();
//       return isValidate;
//     });
//     return isValidate;
//   };

//   !!wizards.length && wizards.each(function (index, value) {
//     var $this = $(value);
//     var controller = $this.data(DATA_KEY.CONTROLLER);
//     var $controller = $(controller);
//     var $buttonPrev = $controller.find(Selector.PREVIOUS_BUTTON);
//     var $modal = $($this.data(DATA_KEY.ERROR_MODAL));
//     var $lottie = $(value).find(Selector.WIZARD_LOTTIE);
//     var options = $.extend({
//       container: value.querySelector(Selector.WIZARD_LOTTIE),
//       renderer: 'svg',
//       loop: true,
//       autoplay: false,
//       name: 'Hello World'
//     }, $lottie.data(DATA_KEY.OPTIONS));
//     var animation = window.bodymovin.loadAnimation(options);
//     $this.bootstrapWizard({
//       tabClass: ClassName.NAV,
//       onNext: function onNext(tab, navigation, idx) {
//         var $currentTab = $this.find(Selector.TAB_PANE).eq(idx - 1);
//         return isFormValidate($currentTab);
//       },
//       onTabClick: function onTabClick(tab, navigation, idx, clickedIndex) {
//         var stepDone = $this.find(".nav-item:nth-child(" + (clickedIndex + 1) + ") .nav-link").data(DATA_KEY.WIZARD_STATE);
//         // for testing
//         return true
//         if (stepDone === 'done') {
//           $modal.modal('show');
//           return false;
//         }

//         if (clickedIndex <= idx) {
//           return true;
//         }

//         var isValid = true;
//         $this.find(Selector.TAB_PANE).each(function (tabIndex, tabValue) {
//           if (tabIndex < clickedIndex) {
//             $this.bootstrapWizard('show', tabIndex);
//             isValid = isFormValidate($(tabValue));
//           }

//           return isValid;
//         });
//         return isValid;
//       },
//       onTabShow: function onTabShow(tab, navigation, idx) {
//         var length = navigation.find('li').length - 1;
//         idx === 0 ? $buttonPrev.hide() : $buttonPrev.show();
//         idx === length && setTimeout(function () {
//           return animation.play();
//         }, 300);
//         $this.find(Selector.NAV_LINK).removeClass(ClassName.DONE);
//         $this.find(Selector.NAV_ITEM).each(function (i, v) {
//           var link = $(v).find(Selector.NAV_LINK);

//           // Application Review Page
//           if (idx === length && !link.hasClass(ClassName.ACTIVE)) {
//             link.attr('data-wizard-state', 'done');
//             formreviewdata()
            
//           }

//           if (!link.hasClass(ClassName.ACTIVE)) {
//             link.addClass(ClassName.DONE);
//             return true;
//           }

//           if (idx === length) {
//             link.addClass(ClassName.DONE);
//             $controller.hide();
//           }


//           return false;
//         });
//       }
//     });
//   });
// });
/*-----------------------------------------------
|   Bulk Actions
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var checkboxBulkSelects = $('.checkbox-bulk-select');

  if (checkboxBulkSelects.length) {
    var Event = {
      CLICK: 'click'
    };
    var Selector = {
      CHECKBOX_BULK_SELECT_CHECKBOX: '.checkbox-bulk-select-target'
    };
    var ClassName = {
      D_NONE: 'd-none'
    };
    var DATA_KEY = {
      CHECKBOX_BODY: 'checkbox-body',
      CHECKBOX_ACTIONS: 'checkbox-actions',
      CHECKBOX_REPLACED_ELEMENT: 'checkbox-replaced-element'
    };
    var Attribute = {
      CHECKED: 'checked',
      INDETERMINATE: 'indeterminate'
    };
    checkboxBulkSelects.each(function (index, value) {
      var checkboxBulkAction = $(value);
      var bulkActions = $(checkboxBulkAction.data(DATA_KEY.CHECKBOX_ACTIONS));
      var replacedElement = $(checkboxBulkAction.data(DATA_KEY.CHECKBOX_REPLACED_ELEMENT));
      var rowCheckboxes = $(checkboxBulkAction.data(DATA_KEY.CHECKBOX_BODY)).find(Selector.CHECKBOX_BULK_SELECT_CHECKBOX);
      checkboxBulkAction.on(Event.CLICK, function () {
        if (checkboxBulkAction.attr(Attribute.INDETERMINATE) === Attribute.INDETERMINATE) {
          bulkActions.addClass(ClassName.D_NONE);
          replacedElement.removeClass(ClassName.D_NONE);
          checkboxBulkAction.prop(Attribute.INDETERMINATE, false).attr(Attribute.INDETERMINATE, false);
          checkboxBulkAction.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
          rowCheckboxes.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
        } else {
          bulkActions.toggleClass(ClassName.D_NONE);
          replacedElement.toggleClass(ClassName.D_NONE);

          if (checkboxBulkAction.attr(Attribute.CHECKED)) {
            checkboxBulkAction.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
          } else {
            checkboxBulkAction.prop(Attribute.CHECKED, true).attr(Attribute.CHECKED, true);
          }

          rowCheckboxes.each(function (i, v) {
            var $this = $(v);

            if ($this.attr(Attribute.CHECKED)) {
              $this.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
            } else {
              $this.prop(Attribute.CHECKED, true).attr(Attribute.CHECKED, true);
            }
          });
        }
      });
      rowCheckboxes.on(Event.CLICK, function (e) {
        var $this = $(e.target);

        if ($this.attr(Attribute.CHECKED)) {
          $this.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
        } else {
          $this.prop(Attribute.CHECKED, true).attr(Attribute.CHECKED, true);
        }

        rowCheckboxes.each(function (i, v) {
          var $elem = $(v);

          if ($elem.attr(Attribute.CHECKED)) {
            checkboxBulkAction.prop(Attribute.INDETERMINATE, true).attr(Attribute.INDETERMINATE, Attribute.INDETERMINATE);
            bulkActions.removeClass(ClassName.D_NONE);
            replacedElement.addClass(ClassName.D_NONE);
            return false;
          }

          if (i === checkboxBulkAction.length) {
            checkboxBulkAction.prop(Attribute.INDETERMINATE, false).attr(Attribute.INDETERMINATE, false);
            checkboxBulkAction.prop(Attribute.CHECKED, false).attr(Attribute.CHECKED, false);
            bulkActions.addClass(ClassName.D_NONE);
            replacedElement.removeClass(ClassName.D_NONE);
          }

          return true;
        });
      });
    });
  }
});
/*-----------------------------------------------
|   Chart
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  /*-----------------------------------------------
  |   Helper functions and Data
  -----------------------------------------------*/
  var _window = window,
      utils = _window.utils;
  var chartData = [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8, 9, 7, 9, 3, 2, 3, 8, 4, 6, 2, 6, 4, 3, 3, 8, 3, 2, 7, 9, 5, 0, 2, 8, 8, 4, 1, 9, 7];
  var labels = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM'];
  /*-----------------------------------------------
  |   Chart Initialization
  -----------------------------------------------*/

  var newChart = function newChart(chart, config) {
    var ctx = chart.getContext('2d');
    return new window.Chart(ctx, config);
  };
  /*-----------------------------------------------
  |   Line Chart
  -----------------------------------------------*/


  var chartLine = document.getElementById('chart-line');

  if (chartLine) {
    var getChartBackground = function getChartBackground(chart) {
      var ctx = chart.getContext('2d');

      if (storage.isDark) {
        var _gradientFill = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

        _gradientFill.addColorStop(0, utils.rgbaColor(utils.colors.primary, 0.5));

        _gradientFill.addColorStop(1, 'transparent');

        return _gradientFill;
      }

      var gradientFill = ctx.createLinearGradient(0, 0, 0, 250);
      gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0.3)');
      gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)');
      return gradientFill;
    };

    var dashboardLineChart = newChart(chartLine, {
      type: 'line',
      data: {
        labels: labels.map(function (label) {
          return label.substring(0, label.length - 3);
        }),
        datasets: [{
          borderWidth: 2,
          data: chartData.map(function (d) {
            return (d * 3.14).toFixed(2);
          }),
          borderColor: utils.settings.chart.borderColor,
          backgroundColor: getChartBackground(chartLine)
        }]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          mode: 'x-axis',
          xPadding: 20,
          yPadding: 10,
          displayColors: false,
          callbacks: {
            label: function label(tooltipItem) {
              return labels[tooltipItem.index] + " - " + tooltipItem.yLabel + " USD";
            },
            title: function title() {
              return null;
            }
          }
        },
        hover: {
          mode: 'label'
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: utils.rgbaColor('#fff', 0.7),
              fontStyle: 600
            },
            gridLines: {
              color: utils.rgbaColor('#fff', 0.1),
              zeroLineColor: utils.rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }],
          yAxes: [{
            display: false
          }]
        }
      }
    });
    $('#dashboard-chart-select').on('change', function (e) {
      var LineDB = {
        all: [4, 1, 6, 2, 7, 12, 4, 6, 5, 4, 5, 10].map(function (d) {
          return (d * 3.14).toFixed(2);
        }),
        successful: [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8].map(function (d) {
          return (d * 3.14).toFixed(2);
        }),
        failed: [1, 0, 2, 1, 2, 1, 1, 0, 0, 1, 0, 2].map(function (d) {
          return (d * 3.14).toFixed(2);
        })
      };
      dashboardLineChart.data.datasets[0].data = LineDB[e.target.value];
      dashboardLineChart.update();
    });
  }
  /*-----------------------------------------------
  |   Bar Chart
  -----------------------------------------------*/


  var chartBar = document.getElementById('chart-bar');

  if (chartBar) {
    newChart(chartBar, {
      type: 'bar',
      data: {
        labels: labels.slice(0, 2),
        datasets: [{
          label: 'First dataset',
          backgroundColor: [utils.rgbaColor(utils.colors.info), utils.rgbaColor(utils.colors.warning)],
          borderColor: [utils.rgbColor(utils.colors.info), utils.rgbColor(utils.colors.warning)],
          borderWidth: 2,
          data: [6, 10]
        }, {
          label: 'Second dataset',
          backgroundColor: [utils.rgbaColor(utils.colors.success), utils.rgbaColor(utils.colors.danger)],
          borderColor: [utils.rgbColor(utils.colors.success), utils.rgbColor(utils.colors.danger)],
          borderWidth: 2,
          data: [3, 7]
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }
  /*-----------------------------------------------
  |   Radar Chart
  -----------------------------------------------*/


  var chartRadar = document.getElementById('chart-radar');

  if (chartRadar) {
    newChart(chartRadar, {
      type: 'radar',
      data: {
        labels: labels,
        datasets: [{
          label: 'First dataset',
          backgroundColor: utils.rgbaColor(utils.colors.warning),
          borderColor: utils.rgbColor(utils.colors.warning),
          borderWidth: 2,
          data: chartData.slice(0, 12),
          fill: 1
        }, {
          label: 'Second dataset',
          backgroundColor: utils.rgbaColor(utils.colors.danger),
          borderColor: utils.rgbColor(utils.colors.danger),
          borderWidth: 2,
          data: chartData.slice(12, 24),
          fill: 1
        }]
      },
      options: {
        maintainAspectRatio: true,
        spanGaps: false,
        elements: {
          line: {
            tension: 0.000001
          }
        }
      }
    });
  }
  /*-----------------------------------------------
  |   Pie Chart
  -----------------------------------------------*/


  var chartPie = document.getElementById('chart-pie');

  if (chartPie) {
    newChart(chartPie, {
      type: 'pie',
      data: {
        labels: labels.slice(0, 3),
        datasets: [{
          backgroundColor: utils.rgbaColors(),
          borderColor: utils.rgbColors(),
          data: chartData.slice(0, 3)
        }]
      },
      options: {
        responsive: true
      }
    });
  }
  /*-----------------------------------------------
  |   Doughnut Chart
  -----------------------------------------------*/


  var chartDoughnut = document.getElementById('chart-doughnut');

  if (chartDoughnut) {
    newChart(chartDoughnut, {
      type: 'doughnut',
      data: {
        labels: labels.slice(0, 3),
        datasets: [{
          backgroundColor: utils.rgbColors(),
          borderColor: utils.rgbColors(),
          data: chartData.slice(0, 3)
        }]
      },
      options: {
        responsive: true
      }
    });
  }
  /*-----------------------------------------------
  |   Polar Area Chart
  -----------------------------------------------*/


  var chartPolarArea = document.getElementById('chart-polar-area');

  if (chartPolarArea) {
    newChart(chartPolarArea, {
      type: 'polarArea',
      data: {
        labels: labels.slice(0, 3),
        datasets: [{
          backgroundColor: utils.rgbaColors(),
          borderColor: utils.rgbaColors(),
          data: chartData.slice(0, 3)
        }]
      },
      options: {
        responsive: true
      }
    });
  }
  /* eslint-disable */

  /*-----------------------------------------------
  |   Polar Bubble
  -----------------------------------------------*/


  var colorize = function colorize(opaque, context) {
    var value = context.dataset.data[context.dataIndex];
    var x = value.x / 100;
    var y = value.y / 100;
    var r = x < 0 && y < 0 ? 250 : x < 0 ? 150 : y < 0 ? 50 : 0;
    var g = x < 0 && y < 0 ? 0 : x < 0 ? 50 : y < 0 ? 150 : 250;
    var b = x < 0 && y < 0 ? 0 : x > 0 && y > 0 ? 250 : 150;
    var a = opaque ? 1 : 0.5 * value.v / 1000;
    return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")";
  };

  var rand = function rand(min, max) {
    var seed = _this._seed;
    min = min === undefined ? 0 : min;
    max = max === undefined ? 1 : max;
    _this._seed = (seed * 9301 + 49297) % 233280;
    return min + _this._seed / 233280 * (max - min);
  };

  var generateData = function generateData() {
    var data = [];
    var DATA_COUNT = 16;
    var MIN_XY = -150;
    var MAX_XY = 100;

    for (var i = 0; i < DATA_COUNT; i += 1) {
      data.push({
        x: rand(MIN_XY, MAX_XY),
        y: rand(MIN_XY, MAX_XY),
        v: rand(0, 1000)
      });
    }

    return data;
  };

  var chartBubble = document.getElementById("chart-bubble");

  if (chartBubble) {
    newChart(chartBubble, {
      type: "bubble",
      data: {
        datasets: [{
          label: ["Deer Population"],
          data: [{
            x: -10,
            y: -20,
            r: 20
          }, {
            x: 100,
            y: 0,
            r: 10
          }, {
            x: 60,
            y: 30,
            r: 20
          }, {
            x: 40,
            y: 60,
            r: 25
          }, {
            x: 80,
            y: 80,
            r: 30
          }, {
            x: 20,
            y: 30,
            r: 25
          }, {
            x: 0,
            y: 100,
            r: 5
          }],
          backgroundColor: "#2C7BE5"
        }]
      }
    });
  }
  /*-----------------------------------------------
  |   Component Line Chart
  -----------------------------------------------*/


  var componentChartLine = document.getElementById("component-chart-line");

  if (componentChartLine) {
    newChart(componentChartLine, {
      type: "line",
      data: {
        labels: labels,
        datasets: [{
          borderWidth: 2,
          data: chartData.slice(2, 14).map(function (d) {
            return (d * 3.14).toFixed(2);
          }),
          borderColor: utils.rgbaColor(utils.colors.primary, 0.4),
          backgroundColor: utils.rgbaColor(utils.colors.primary)
        }, {
          borderWidth: 2,
          borderColor: "#fff",
          data: chartData.slice(3, 15).map(function (d) {
            return (d * 3.14).toFixed(2);
          }),
          backgroundColor: utils.rgbaColor(utils.colors.primary)
        }]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          mode: "x-axis",
          xPadding: 20,
          yPadding: 10,
          displayColors: false,
          callbacks: {
            label: function label(tooltipItem) {
              return labels[tooltipItem.index] + " - " + tooltipItem.yLabel + " USD";
            },
            title: function title() {
              return null;
            }
          }
        },
        hover: {
          mode: "label"
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              show: true,
              labelString: "Month"
            },
            ticks: {
              fontColor: utils.rgbaColor("#000", 0.7),
              fontStyle: 600
            },
            gridLines: {
              // color: utils.rgbaColor('#000', 0.1),
              color: utils.rgbaColor("#000", 0.1),
              zeroLineColor: utils.rgbaColor("#000", 0.1),
              lineWidth: 1
            }
          }],
          yAxes: [{
            display: false
          }]
        }
      }
    });
  }
  /*-----------------------------------------------
  |   Real time user
  -----------------------------------------------*/

  /*-----------------------------------------------
  |   Bar Chart
  -----------------------------------------------*/


  var realTimeUser = document.getElementById("real-time-user");

  if (realTimeUser) {
    var realTimeUserChart = newChart(realTimeUser, {
      type: "bar",
      data: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
        datasets: [{
          label: "Users",
          backgroundColor: utils.rgbaColor("#fff", 0.3),
          data: [183, 163, 176, 172, 166, 161, 164, 159, 172, 173, 184, 163, 99, 173, 183, 167, 160, 183, 163, 176, 172, 166, 173, 188, 175],
          barPercentage: 0.9,
          categoryPercentage: 1.0
        }]
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            display: false,
            stacked: true
          }],
          xAxes: [{
            stacked: false,
            ticks: {
              display: false
            },
            gridLines: {
              color: utils.rgbaColor("#fff", 0.1),
              display: false
            }
          }]
        }
      }
    });
    var userCounterDom = $(".real-time-user");
    setInterval(function () {
      var userCounter = Math.floor(Math.random() * (120 - 60) + 60);
      /*-----------------------------------------------
      |   Remove data
      -----------------------------------------------*/

      realTimeUserChart.data.datasets.forEach(function (dataset) {
        dataset.data.shift();
      });
      realTimeUserChart.update();
      /*-----------------------------------------------
      |   Add data
      -----------------------------------------------*/

      setTimeout(function () {
        realTimeUserChart.data.datasets.forEach(function (dataset) {
          dataset.data.push(userCounter);
        });
        realTimeUserChart.update();
        userCounterDom.text(userCounter);
      }, 500);
    }, 2000);
  }
});

/*-----------------------------------------------
|   Count Up
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $counters = $('[data-countup]');

  if ($counters.length) {
    $counters.each(function (index, value) {
      var $counter = $(value);
      var counter = $counter.data('countup');

      var toAlphanumeric = function toAlphanumeric(num) {
        var number = num;
        var abbreviations = {
          k: 1000,
          m: 1000000,
          b: 1000000000,
          t: 1000000000000
        };

        if (num < abbreviations.m) {
          number = (num / abbreviations.k).toFixed(2) + "k";
        } else if (num < abbreviations.b) {
          number = (num / abbreviations.m).toFixed(2) + "m";
        } else if (num < abbreviations.t) {
          number = (num / abbreviations.b).toFixed(2) + "b";
        } else {
          number = (num / abbreviations.t).toFixed(2) + "t";
        }

        return number;
      };

      var toComma = function toComma(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      };

      var toSpace = function toSpace(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      };

      var playCountUpTriggered = false;

      var countUP = function countUP() {
        if (utils.isScrolledIntoView(value) && !playCountUpTriggered) {
          if (!playCountUpTriggered) {
            $({
              countNum: 0
            }).animate({
              countNum: counter.count
            }, {
              duration: counter.duration || 1000,
              easing: 'linear',
              step: function step() {
                $counter.text((counter.prefix ? counter.prefix : '') + Math.floor(this.countNum));
              },
              complete: function complete() {
                switch (counter.format) {
                  case 'comma':
                    $counter.text((counter.prefix ? counter.prefix : '') + toComma(this.countNum));
                    break;

                  case 'space':
                    $counter.text((counter.prefix ? counter.prefix : '') + toSpace(this.countNum));
                    break;

                  case 'alphanumeric':
                    $counter.text((counter.prefix ? counter.prefix : '') + toAlphanumeric(this.countNum));
                    break;

                  default:
                    $counter.text((counter.prefix ? counter.prefix : '') + this.countNum);
                }
              }
            });
            playCountUpTriggered = true;
          }
        }

        return playCountUpTriggered;
      };

      countUP();
      utils.$window.scroll(function () {
        countUP();
      });
    });
  }
});
/*-----------------------------------------------
|   Data table
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  // $.fn.dataTable.ext.classes.sPageButton = 'btn btn-falcon-default btn-sm';
  var dataTables = $('.data-table');

  var customDataTable = function customDataTable(elem) {
    elem.find('.pagination').addClass('pagination-sm')    
    // .closest('[class*="col"]')
    //   .removeClass('col-sm-12 col-md-7')
    //   .addClass('col-auto mt-2 px-1')
    //   .closest('.row')
    //   .addClass('no-gutters justify-content-center justify-content-md-between px-3 pb-3');
    // elem.find('.pagination').addClass('pagination-sm');
  };

  dataTables.length && dataTables.each(function (index, value) {
    var dtable = $(value);
    var options = $.extend({
      // responsive: true,
      order: [[ 0, 'asc'], [ 1, 'asc'], [2, 'asc'], [3, 'asc'], [4, 'asc'], [5, 'asc']],
      dom:"<'row mx-1'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>><'table-responsive'tr><'row no-gutters px-1 py-3 align-items-center justify-content-center'<'col-auto'p>>"
    },dtable.data('options'));
    dtable.DataTable(options);
    var $wrpper = dtable.closest('.dataTables_wrapper');
    customDataTable($wrpper);
    dtable.on('draw.dt', function () {
      return customDataTable($wrpper);
    });
  });
});

document.addEventListener("turbolinks:before-cache", () => {
  var dataTables = $('.data-table');
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }
});
/*-----------------------------------------------
|   Countdown
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $dataCountdowns = $('[data-countdown]');
  var DATA_KEY = {
    FALLBACK: 'countdown-fallback',
    COUNTDOWN: 'countdown'
  };

  if ($dataCountdowns.length) {
    $dataCountdowns.each(function (index, value) {
      var $dateCountdown = $(value);
      var date = $dateCountdown.data(DATA_KEY.COUNTDOWN);
      var fallback;

      if (typeof $dateCountdown.data(DATA_KEY.FALLBACK) !== typeof undefined) {
        fallback = $dateCountdown.data(DATA_KEY.FALLBACK);
      }

      $dateCountdown.countdown(date, function (event) {
        if (event.elapsed) {
          $dateCountdown.text(fallback);
        } else {
          $dateCountdown.text(event.strftime('%D days %H:%M:%S'));
        }
      });
    });
  }
});
/*-----------------------------------------------
|   Demo mode
-----------------------------------------------*/

// document.addEventListener("turbolinks:load", function() {
//   var _window2 = window,
//       utils = _window2.utils,
//       location = _window2.location;
//   var Event = {
//     CHANGE: 'change'
//   };
//   var Selector = {
//     RTL: '#mode-rtl',
//     FLUID: '#mode-fluid',
//     INPUT_NAVBAR: "input[name='navbar']",
//     INPUT_COLOR_SCHEME: "input[name='colorScheme']",
//     NAVBAR_STYLE_TRANSPARENT: '#navbar-style-transparent',
//     NAVBAR_STYLE_INVERTED: '#navbar-style-inverted',
//     NAVBAR_STYLE_VIBRANT: '#navbar-style-vibrant',
//     NAVBAR_STYLE_WHITE: '#navbar-style-card'
//   };
//   var DATA_KEY = {
//     URL: 'url',
//     HOME_URL: 'home-url',
//     PAGE_URL: 'page-url'
//   }; // Redirect on Checkbox change

//   var handleChange = function handleChange(selector) {
//     utils.$document.on(Event.CHANGE, selector, function (e) {
//       var $this = $(e.currentTarget);
//       var isChecked = $this.prop('checked');

//       if (isChecked) {
//         var url = $this.data(DATA_KEY.URL);
//         location.replace(url);
//       } else {
//         var homeUrl = $this.data(DATA_KEY.HOME_URL);
//         location.replace(homeUrl);
//       }
//     });
//   };

//   var handleInputChange = function handleInputChange(selector) {
//     utils.$document.on(Event.CHANGE, selector, function (e) {
//       var $this = $(e.currentTarget);
//       var pageUrl = $this.data(DATA_KEY.PAGE_URL);
//       location.replace(pageUrl);
//     });
//   }; // Mode checkbox handler


//   handleChange(Selector.RTL);
//   handleChange(Selector.FLUID);
//   handleInputChange(Selector.INPUT_NAVBAR);
//   handleInputChange(Selector.INPUT_COLOR_SCHEME);
//   handleInputChange(Selector.NAVBAR_STYLE_TRANSPARENT);
//   handleInputChange(Selector.NAVBAR_STYLE_INVERTED);
//   handleInputChange(Selector.NAVBAR_STYLE_VIBRANT);
//   handleInputChange(Selector.NAVBAR_STYLE_WHITE);
// });
/*-----------------------------------------------
|   Documentation and Component Navigation
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $componentNav = $('#components-nav');

  if ($componentNav.length) {
    var loc = window.location.href;

    var _loc$split = loc.split('#');

    loc = _loc$split[0];
    var location = loc.split('/');
    var url = location[location.length - 2] + "/" + location.pop();
    var urls = $componentNav.children('li').children('a');

    for (var i = 0, max = urls.length; i < max; i += 1) {
      var dom = urls[i].href.split('/');
      var domURL = dom[dom.length - 2] + "/" + dom.pop();

      if (domURL === url) {
        var $targetedElement = $(urls[i]);
        $targetedElement.removeClass('text-800');
        $targetedElement.addClass('font-weight-medium');
        break;
      }
    }
  }
});
/*-----------------------------------------------
|   Draggable
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selectors = {
    BODY: 'body',
    KANBAN_CONTAINER: '.kanban-container',
    KANBAN_ITEMS_CONTAINER: '.kanban-items-container',
    KANBAN_ITEM: '.kanban-item',
    KANBAN_COLLAPSE: "[data-collapse='kanban']",
    PS_RAILS: '.ps__rail-x, .ps__rail-y' // Perfect scrollbar rails in IE

  };
  var Events = {
    DRAG_START: 'drag:start',
    DRAG_STOP: 'drag:stop'
  };
  var columns = document.querySelectorAll(Selectors.KANBAN_ITEMS_CONTAINER);
  var container = document.querySelector(Selectors.KANBAN_CONTAINER);
  var scrollItems = $(Selectors.KANBAN_ITEMS_CONTAINER);
  var scrollableElements = [];
  scrollItems.each(function (index, item) {
    scrollableElements[index] = item;
  });

  if (columns.length) {
    // Initialize Sortable
    var sortable = new window.Draggable.Sortable(columns, {
      draggable: Selectors.KANBAN_ITEM,
      delay: 200,
      mirror: {
        appendTo: Selectors.BODY,
        constrainDimensions: true
      },
      scrollable: {
        draggable: Selectors.KANBAN_ITEM,
        scrollableElements: [].concat(scrollableElements, [container])
      }
    }); // Hide form when drag start

    sortable.on(Events.DRAG_START, function () {
      $(Selectors.KANBAN_COLLAPSE).collapse('hide');
    }); // Place forms and other contents bottom of the sortable container

    sortable.on(Events.DRAG_STOP, function (e) {
      var $this = $(e.data.source);
      var $itemContainer = $this.closest(Selectors.KANBAN_ITEMS_CONTAINER);
      var $collapse = $this.closest(Selectors.KANBAN_ITEMS_CONTAINER).find(Selectors.KANBAN_COLLAPSE);
      $this.is(':last-child') && $itemContainer.append($collapse); // For IE

      if (window.is.ie()) {
        var $rails = $itemContainer.find(Selectors.PS_RAILS);
        $itemContainer.append($rails);
      }
    });
  }
});
/*-----------------------------------------------
|   Dashboard Table dropdown
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  // Only for ios
  if (window.is.ios()) {
    var Event = {
      SHOWN_BS_DROPDOWN: 'shown.bs.dropdown',
      HIDDEN_BS_DROPDOWN: 'hidden.bs.dropdown'
    };
    var Selector = {
      TABLE_RESPONSIVE: '.table-responsive',
      DROPDOWN_MENU: '.dropdown-menu'
    };
    $(Selector.TABLE_RESPONSIVE).on(Event.SHOWN_BS_DROPDOWN, function dashboardTableDropdown(e) {
      var t = $(this);
      var m = $(e.target).find(Selector.DROPDOWN_MENU);
      var tb = t.offset().top + t.height();
      var mb = m.offset().top + m.outerHeight(true);
      var d = 20; // Space for shadow + scrollbar.

      if (t[0].scrollWidth > t.innerWidth()) {
        if (mb + d > tb) {
          t.css('padding-bottom', mb + d - tb);
        }
      } else {
        t.css('overflow', 'visible');
      }
    }).on(Event.HIDDEN_BS_DROPDOWN, function (e) {
      var $this = $(e.currentTarget);
      $this.css({
        'padding-bottom': '',
        overflow: ''
      });
    });
  }
}); // Reference
// https://github.com/twbs/bootstrap/issues/11037#issuecomment-274870381

/*-----------------------------------------------
|   Documentation and Component Navigation
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selector = {
    NAVBAR_THEME_DROPDOWN: '.navbar-theme .dropdown',
    DROPDOWN_ON_HOVER: '.dropdown-on-hover',
    DROPDOWN_MENU: '.dropdown-menu',
    DATA_TOGGLE_DROPDOWN: '[data-toggle="dropdown"]',
    BODY: 'body',
    DROPDOWN_MENU_ANCHOR: '.dropdown-menu a'
  };
  var ClassName = {
    DROPDOWN_ON_HOVER: 'dropdown-on-hover',
    SHOW: 'show'
  };
  var Event = {
    CLICK: 'click',
    MOUSE_LEAVE: 'mouseleave',
    MOUSE_EVENT: 'mouseenter mouseleave'
  };
  var Attribute = {
    ARIA_EXPANDED: 'aria-expanded'
  };
  var $navbarDropdown = $(Selector.NAVBAR_THEME_DROPDOWN);

  if (!window.is.mobile()) {
    $navbarDropdown.addClass(ClassName.DROPDOWN_ON_HOVER);
  } else {
    $navbarDropdown.removeClass(ClassName.DROPDOWN_ON_HOVER);
  }

  var toggleDropdown = function toggleDropdown(e) {
    var $el = $(e.target);
    var dropdown = $el.closest(Selector.DROPDOWN_ON_HOVER);
    var dropdownMenu = $(Selector.DROPDOWN_MENU, dropdown);
    setTimeout(function () {
      var shouldOpen = e.type !== Event.CLICK && dropdown.is(':hover');
      dropdownMenu.toggleClass(ClassName.SHOW, shouldOpen);
      dropdown.toggleClass(ClassName.SHOW, shouldOpen);
      $(Selector.DATA_TOGGLE_DROPDOWN, dropdown).attr(Attribute.ARIA_EXPANDED, shouldOpen);
    }, e.type === Event.MOUSE_LEAVE ? 100 : 0);
  };

  $(Selector.BODY).on(Event.MOUSE_EVENT, Selector.DROPDOWN_ON_HOVER, toggleDropdown).on(Event.CLICK, Selector.DROPDOWN_MENU_ANCHOR, toggleDropdown);
});
/*-----------------------------------------------
|   Dropzone
-----------------------------------------------*/

Dropzone ? Dropzone.autoDiscover = false : '';
document.addEventListener("turbolinks:load", function() {
  var Selector = {
    DROPZONE: '[data-dropzone]',
    DZ_ERROR_MESSAGE: '.dz-error-message',
    DZ_PREVIEW: '.dz-preview',
    DZ_PROGRESS: '.dz-preview .dz-preview-cover .dz-progress',
    DZ_PREVIEW_COVER: '.dz-preview .dz-preview-cover'
  };
  var ClassName = {
    DZ_FILE_PROCESSING: 'dz-file-processing',
    DZ_FILE_COMPLETE: 'dz-file-complete',
    DZ_COMPLETE: 'dz-complete',
    DZ_PROCESSING: 'dz-processing'
  };
  var DATA_KEY = {
    OPTIONS: 'options'
  };
  var Events = {
    ADDED_FILE: 'addedfile',
    COMPLETE: 'complete',
    SUCCESS: 'success',
    ERROR: 'error'
  };
  var dropzones = $(Selector.DROPZONE);
  !!dropzones.length && dropzones.each(function (index, value) {
    var element = value;
    var $this = $(element);
    var userOptions = $this.data(DATA_KEY.OPTIONS);
    userOptions = userOptions || {};
    var data = userOptions.data ? userOptions.data : {};
    var options = $.extend({
      url: '/assets/php/',
      addRemoveLinks: false,
      previewsContainer: element.querySelector(Selector.DZ_PREVIEW),
      previewTemplate: element.querySelector(Selector.DZ_PREVIEW).innerHTML,
      thumbnailWidth: null,
      thumbnailHeight: null,
      init: function init() {
        var thisDropzone = this;

        if (data.length) {
          $.each(data, function (i, v) {
            var mockFile = {
              name: v.name,
              size: v.size
            };
            thisDropzone.options.addedfile.call(thisDropzone, mockFile);
            thisDropzone.options.thumbnail.call(thisDropzone, mockFile, v.url + "/" + v.name);
          });
        }

        thisDropzone.on(Events.ADDED_FILE, function addedfile() {
          if ('maxFiles' in userOptions) {
            if (userOptions.maxFiles === 1 && $this.find(Selector.DZ_PREVIEW_COVER).length > 1) {
              $this.find(Selector.DZ_PREVIEW_COVER).first().remove();
            }

            if (userOptions.maxFiles === 1 && this.files.length > 1) {
              this.removeFile(this.files[0]);
            }
          }
        });
      }
    }, userOptions);
    element.querySelector(Selector.DZ_PREVIEW).innerHTML = '';
    var dropzone = new Dropzone(value, options);
    dropzone.on(Events.ADDED_FILE, function () {
      $this.find(Selector.DZ_PREVIEW_COVER).removeClass(ClassName.DZ_FILE_COMPLETE);
      $this.addClass(ClassName.DZ_FILE_PROCESSING);
    });
    dropzone.on(Events.COMPLETE, function () {
      $this.find(Selector.DZ_PREVIEW_COVER).removeClass(ClassName.DZ_PROCESSING);
      $this.addClass(ClassName.DZ_FILE_COMPLETE);
    });
    dropzone.on(Events.SUCCESS, function () {
      if ($this.attr("id") == "dropzone_hkid") {
        $("#manual_upload_hkid").addClass("d-none");
        $("#hkid_success").removeClass("d-none");
        $("#hkid_verify_not_completed").addClass("d-none");
      }
      if ($this.attr("id") == "dropzone_address") {
        $("#manual_upload_address").addClass("d-none");
        $("#address_success").removeClass("d-none");
        $("#address_verify_not_completed").addClass("d-none");
      }
      if ($this.attr("id") == "dropzone_card") {
        $("#manual_upload_card").addClass("d-none");
        $("#card_success").removeClass("d-none");
        $("#card_verify_not_completed").addClass("d-none");
      }
      if (["dropzone_payslip", "dropzone_payslip_1", "dropzone_payslip_2", "dropzone_bank_account", "dropzone_supporting_document"].includes($this.attr("id")) ) {
        console.log(dropzones)
        var len_payslip = window.Dropzone.forElement("#dropzone_payslip").getAcceptedFiles().length;
        var len_payslip_1 = window.Dropzone.forElement("#dropzone_payslip_1").getAcceptedFiles().length;
        var len_payslip_2 = window.Dropzone.forElement("#dropzone_payslip_2").getAcceptedFiles().length;
        var len_bank_account = window.Dropzone.forElement("#dropzone_bank_account").getAcceptedFiles().length;
        var len_supporting_document = window.Dropzone.forElement("#dropzone_supporting_document").getAcceptedFiles().length;
        if ((len_payslip + len_payslip_1 + len_payslip_2) > 0 && (len_bank_account > 0)) {
          $("#financial_verification_container").addClass("d-none");
          $("#financials_success").removeClass("d-none");
        }
      }
    });
    dropzone.on(Events.ERROR, function () {
      $("#manual_upload_address").addClass("d-none");
      $("#address_success").removeClass("d-none");
      $("#address_verify_not_completed").addClass("d-none");
    });
  });
});

/*-----------------------------------------------
|   On page scroll for #id targets
-----------------------------------------------*/

$(function ($) {
  $('a[data-fancyscroll]').click(function scrollTo(e) {
    // const $this = $(e.currentTarget);
    var $this = $(this);

    if (utils.location.pathname === $this[0].pathname && utils.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && utils.location.hostname === this.hostname) {
      e.preventDefault();
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");

      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - ($this.data('offset') || 0)
        }, 400, 'swing', function () {
          var hash = $this.attr('href');
          window.history.pushState ? window.history.pushState(null, null, hash) : window.location.hash = hash;
        });
        return false;
      }
    }

    return true;
  });
  var hash = window.location.hash;

  if (hash && document.getElementById(hash.slice(1))) {
    var $this = $(hash);
    $('html,body').animate({
      scrollTop: $this.offset().top - $("a[href='" + hash + "']").data('offset')
    }, 400, 'swing', function () {
      window.history.pushState ? window.history.pushState(null, null, hash) : window.location.hash = hash;
    });
  }
});
/*-----------------------------------------------
|   File Input
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  $('.custom-file-input').on('change', function (e) {
    var $this = $(e.currentTarget);
    var fileName = $this.val().split('\\').pop();
    $this.next('.custom-file-label').addClass('selected').html(fileName);
  });
});
/*-----------------------------------------------
|   Flatpickr
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var datetimepicker = $('.datetimepicker');
  datetimepicker.length && datetimepicker.each(function (index, value) {
    var $this = $(value);
    var flatpickr_locale = english;
    if (document.documentElement.lang === "zh-HK") {
      flatpickr_locale = MandarinTraditional
    }
    var max_date = moment().locale("en-gb").subtract(18, 'years').calendar();
    var options = $.extend({
      dateFormat: 'd/m/y',
      disableMobile: true,
      locale: flatpickr_locale,
      maxDate: max_date
    }, $this.data('options'));
    $this.attr('placeholder', options.dateFormat);
    $this.flatpickr(options);
  });
});
/*-----------------------------------------------
|   Bootstrap validation
-----------------------------------------------*/

(function () {
  window.addEventListener('load', function () {
    // Fetch all the forms we want to apply theme Bootstrap validation styles to
    var forms = document.getElementsByClassName('needs-validation'); // Loop over them and prevent submission

    Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      }, false);
    });
  }, false);
})();
/*-----------------------------------------------
|   Gooogle Map
-----------------------------------------------*/

/*
  global google
*/


function initMap() {
  var $googlemaps = $('.googlemap');
  var isDark = storage.isDark;

  if ($googlemaps.length) {
    // Visit https://snazzymaps.com/ for more themes
    var mapStyles = {
      Default: [{
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          color: '#e9e9e9'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{
          color: '#f5f5f5'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#ffffff'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#ffffff'
        }, {
          lightness: 29
        }, {
          weight: 0.2
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{
          color: '#ffffff'
        }, {
          lightness: 18
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          color: '#ffffff'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          color: '#f5f5f5'
        }, {
          lightness: 21
        }]
      }, {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{
          color: '#dedede'
        }, {
          lightness: 21
        }]
      }, {
        elementType: 'labels.text.stroke',
        stylers: [{
          visibility: 'on'
        }, {
          color: '#ffffff'
        }, {
          lightness: 16
        }]
      }, {
        elementType: 'labels.text.fill',
        stylers: [{
          saturation: 36
        }, {
          color: '#333333'
        }, {
          lightness: 40
        }]
      }, {
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{
          color: '#f2f2f2'
        }, {
          lightness: 19
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#fefefe'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#fefefe'
        }, {
          lightness: 17
        }, {
          weight: 1.2
        }]
      }],
      Gray: [{
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{
          saturation: 36
        }, {
          color: '#000000'
        }, {
          lightness: 40
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{
          visibility: 'on'
        }, {
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }, {
          weight: 1.2
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 21
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 29
        }, {
          weight: 0.2
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 18
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 19
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }]
      }],
      Midnight: [{
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{
          color: '#ffffff'
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 13
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#144b53'
        }, {
          lightness: 14
        }, {
          weight: 1.4
        }]
      }, {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{
          color: '#08304b'
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          color: '#0c4152'
        }, {
          lightness: 5
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#0b434f'
        }, {
          lightness: 25
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#0b3d51'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }]
      }, {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{
          color: '#146474'
        }]
      }, {
        featureType: 'water',
        elementType: 'all',
        stylers: [{
          color: '#021019'
        }]
      }],
      Hopper: [{
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          hue: '#165c64'
        }, {
          saturation: 34
        }, {
          lightness: -69
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{
          hue: '#b7caaa'
        }, {
          saturation: -14
        }, {
          lightness: -18
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [{
          hue: '#cbdac1'
        }, {
          saturation: -6
        }, {
          lightness: -9
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{
          hue: '#8d9b83'
        }, {
          saturation: -89
        }, {
          lightness: -12
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{
          hue: '#d4dad0'
        }, {
          saturation: -88
        }, {
          lightness: 54
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{
          hue: '#bdc5b6'
        }, {
          saturation: -89
        }, {
          lightness: -3
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          hue: '#bdc5b6'
        }, {
          saturation: -89
        }, {
          lightness: -26
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          hue: '#c17118'
        }, {
          saturation: 61
        }, {
          lightness: -45
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'poi.park',
        elementType: 'all',
        stylers: [{
          hue: '#8ba975'
        }, {
          saturation: -46
        }, {
          lightness: -28
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{
          hue: '#a43218'
        }, {
          saturation: 74
        }, {
          lightness: -51
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [{
          hue: '#ffffff'
        }, {
          saturation: 0
        }, {
          lightness: 100
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'administrative.neighborhood',
        elementType: 'all',
        stylers: [{
          hue: '#ffffff'
        }, {
          saturation: 0
        }, {
          lightness: 100
        }, {
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative.locality',
        elementType: 'labels',
        stylers: [{
          hue: '#ffffff'
        }, {
          saturation: 0
        }, {
          lightness: 100
        }, {
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative.land_parcel',
        elementType: 'all',
        stylers: [{
          hue: '#ffffff'
        }, {
          saturation: 0
        }, {
          lightness: 100
        }, {
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [{
          hue: '#3a3935'
        }, {
          saturation: 5
        }, {
          lightness: -57
        }, {
          visibility: 'off'
        }]
      }, {
        featureType: 'poi.medical',
        elementType: 'geometry',
        stylers: [{
          hue: '#cba923'
        }, {
          saturation: 50
        }, {
          lightness: -46
        }, {
          visibility: 'on'
        }]
      }],
      Beard: [{
        featureType: 'poi.business',
        elementType: 'labels.text',
        stylers: [{
          visibility: 'on'
        }, {
          color: '#333333'
        }]
      }],
      AssassianCreed: [{
        featureType: 'all',
        elementType: 'all',
        stylers: [{
          visibility: 'on'
        }]
      }, {
        featureType: 'all',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }, {
          saturation: '-100'
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.text.fill',
        stylers: [{
          saturation: 36
        }, {
          color: '#000000'
        }, {
          lightness: 40
        }, {
          visibility: 'off'
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [{
          visibility: 'off'
        }, {
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'all',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 17
        }, {
          weight: 1.2
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 20
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#4d6059'
        }]
      }, {
        featureType: 'landscape',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#4d6059'
        }]
      }, {
        featureType: 'landscape.natural',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#4d6059'
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{
          lightness: 21
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#4d6059'
        }]
      }, {
        featureType: 'poi',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#4d6059'
        }]
      }, {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{
          visibility: 'on'
        }, {
          color: '#7f8d89'
        }]
      }, {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#7f8d89'
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#7f8d89'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#7f8d89'
        }, {
          lightness: 29
        }, {
          weight: 0.2
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 18
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#7f8d89'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#7f8d89'
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 16
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#7f8d89'
        }]
      }, {
        featureType: 'road.local',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#7f8d89'
        }]
      }, {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{
          color: '#000000'
        }, {
          lightness: 19
        }]
      }, {
        featureType: 'water',
        elementType: 'all',
        stylers: [{
          color: '#2b3638'
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          color: '#2b3638'
        }, {
          lightness: 17
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{
          color: '#24282b'
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry.stroke',
        stylers: [{
          color: '#24282b'
        }]
      }, {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [{
          visibility: 'off '
        }]
      }, {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'water',
        elementType: 'labels.icon',
        stylers: [{
          visibility: 'off'
        }]
      }],
      SubtleGray: [{
        featureType: 'administrative',
        elementType: 'all',
        stylers: [{
          saturation: '-100'
        }]
      }, {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{
          saturation: -100
        }, {
          lightness: 65
        }, {
          visibility: 'on'
        }]
      }, {
        featureType: 'poi',
        elementType: 'all',
        stylers: [{
          saturation: -100
        }, {
          lightness: '50'
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'road',
        elementType: 'all',
        stylers: [{
          saturation: -100
        }]
      }, {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [{
          visibility: 'simplified'
        }]
      }, {
        featureType: 'road.arterial',
        elementType: 'all',
        stylers: [{
          lightness: '30'
        }]
      }, {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [{
          lightness: '40'
        }]
      }, {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{
          saturation: -100
        }, {
          visibility: 'simplified'
        }]
      }, {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{
          hue: '#ffff00'
        }, {
          lightness: -25
        }, {
          saturation: -97
        }]
      }, {
        featureType: 'water',
        elementType: 'labels',
        stylers: [{
          lightness: -25
        }, {
          saturation: -100
        }]
      }],
      Tripitty: [{
        featureType: 'all',
        elementType: 'labels',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'administrative',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [{
          color: '#2c5ca5'
        }]
      }, {
        featureType: 'poi',
        elementType: 'all',
        stylers: [{
          color: '#2c5ca5'
        }]
      }, {
        featureType: 'road',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'transit',
        elementType: 'all',
        stylers: [{
          visibility: 'off'
        }]
      }, {
        featureType: 'water',
        elementType: 'all',
        stylers: [{
          color: '#193a70'
        }, {
          visibility: 'on'
        }]
      }]
    };
    $googlemaps.each(function (index, value) {
      var $googlemap = $(value);
      var latLng = $googlemap.data('latlng').split(',');
      var markerPopup = $googlemap.html();
      var icon = $googlemap.data('icon') ? $googlemap.data('icon') : 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png';
      var zoom = $googlemap.data('zoom');
      var mapStyle = isDark ? 'Midnight' : $googlemap.data('theme');
      var mapElement = value;

      if ($googlemap.data('theme') === 'streetview') {
        var pov = $googlemap.data('pov');
        var _mapOptions = {
          position: {
            lat: Number(latLng[0]),
            lng: Number(latLng[1])
          },
          pov: pov,
          zoom: zoom,
          gestureHandling: 'none',
          scrollwheel: false
        };
        return new google.maps.StreetViewPanorama(mapElement, _mapOptions);
      }

      var mapOptions = {
        zoom: zoom,
        scrollwheel: $googlemap.data('scrollwheel'),
        center: new google.maps.LatLng(latLng[0], latLng[1]),
        styles: mapStyles[mapStyle]
      };
      var map = new google.maps.Map(mapElement, mapOptions);
      var infowindow = new google.maps.InfoWindow({
        content: markerPopup
      });
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latLng[0], latLng[1]),
        icon: icon,
        map: map
      });
      marker.addListener('click', function () {
        infowindow.open(map, marker);
      });
      return null;
    });
  }
}
/*-----------------------------------------------
|   Incrment/Decrement Input Fields
-----------------------------------------------*/


document.addEventListener("turbolinks:load", function() {
  var Selector = {
    DATA_FIELD: '[data-field]',
    INPUT_GROUP: '.input-group'
  };
  var DATA_KEY = {
    FIELD: 'field',
    TYPE: 'type'
  };
  var Events = {
    CLICK: 'click'
  };
  var Attributes = {
    MIN: 'min'
  };
  utils.$document.on(Events.CLICK, Selector.DATA_FIELD, function (e) {
    var $this = $(e.target);
    var inputField = $this.data(DATA_KEY.FIELD);
    var $inputField = $this.parents(Selector.INPUT_GROUP).children("." + inputField);
    var $btnType = $this.data(DATA_KEY.TYPE);
    var value = parseInt($inputField.val(), 10);
    var min = $inputField.attr(Attributes.MIN);

    if (min) {
      min = parseInt(min, 10);
    } else {
      min = 0;
    }

    if ($btnType === 'plus') {
      value += 1;
    } else if (value > min) {
      value -= 1;
    }

    $inputField.val(value);
  });
});

/*-----------------------------------------------
|   Draggable
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selectors = {
    ADD_LIST_FORM: '#add-list-form',
    KANBAN_COLUMN: '.kanban-column',
    BTN_ADD_CARD: '.btn-add-card',
    KANBAN_ITEMS_CONTAINER: '.kanban-items-container',
    COLLAPSE: '.collapse',
    COLLAPSE_CLOSE: "[data-dismiss='collapse']",
    ADD_CARD: '.add-card',
    ADD_LIST: '.add-list',
    MODAL_ANCHOR: "[data-toggle='modal'] a",
    HIDE_ADD_CARD_FORM: '.hide-add-card-form'
  };
  var Events = {
    TOGGLE_BS_COLLAPSE: 'show.bs.collapse hide.bs.collapse',
    SHOWN_BS_COLLAPSE: 'shown.bs.collapse',
    CLICK: 'click'
  };
  var ClassNames = {
    FORM_ADDED: 'form-added',
    D_NONE: 'd-none'
  }; // Hide button when Add list form is shown

  $(Selectors.ADD_LIST_FORM).on(Events.TOGGLE_BS_COLLAPSE, function (e) {
    var $this = $(e.currentTarget);
    $this.next().toggleClass(ClassNames.D_NONE);
  }); // Show card add form and scroll to the bottom

  $(document).on(Events.CLICK, Selectors.BTN_ADD_CARD, function (e) {
    var $this = $(e.currentTarget);
    var $column = $this.closest(Selectors.KANBAN_COLUMN);
    var $container = $column.find(Selectors.KANBAN_ITEMS_CONTAINER);
    var scrollHeight = $container[0].scrollHeight;
    $column.addClass(ClassNames.FORM_ADDED);
    $container.scrollTop(scrollHeight);
    $container.find(Selectors.ADD_CARD).focus();
  }); // Hide card add form

  $(document).on(Events.CLICK, Selectors.HIDE_ADD_CARD_FORM, function (e) {
    var $this = $(e.currentTarget);
    var $column = $this.closest(Selectors.KANBAN_COLUMN);
    $column.removeClass(ClassNames.FORM_ADDED);
  }); // Focus add list form on form shown

  $(Selectors.ADD_LIST_FORM).on(Events.SHOWN_BS_COLLAPSE, function (e) {
    var $this = $(e.currentTarget);
    $this.find(Selectors.ADD_LIST).focus();
  });
  utils.$document.on(Events.CLICK, Selectors.MODAL_ANCHOR, function (e) {
    e.stopPropagation();
  }); // Close collapse when corresponding close button is clicked

  utils.$document.on(Events.CLICK, Selectors.COLLAPSE_CLOSE, function (e) {
    var $this = $(e.currentTarget);
    $this.closest(Selectors.COLLAPSE).collapse('hide');
  });
});

/*-----------------------------------------------
|   Lightbox
-----------------------------------------------*/

/*
  global lightbox
*/

document.addEventListener("turbolinks:load", function() {
  if ($('[data-lightbox]').length) {
    lightbox.option({
      resizeDuration: 400,
      wrapAround: true,
      fadeDuration: 300,
      imageFadeDuration: 300
    });
  }
});
/*-----------------------------------------------
|   Lottie
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var lotties = $('.lottie');

  if (lotties.length) {
    lotties.each(function (index, value) {
      var $this = $(value);
      var options = $.extend({
        container: value,
        path: '../asset/images/warning-light.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: 'Hello World'
      }, $this.data('options'));
      window.bodymovin.loadAnimation(options);
    });
  }
});
/*-----------------------------------------------
|   Modal
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selector = {
    MODAL_THEME: '.modal-theme'
  };
  var DataKey = {
    OPTIONS: 'options'
  };
  var Events = {
    HIDDEN_BS_MODAL: 'hidden.bs.modal'
  };
  var modals = $(Selector.MODAL_THEME);
  var showModal = true;

  if (modals.length) {
    modals.each(function (index, value) {
      var $this = $(value);
      var userOptions = $this.data(DataKey.OPTIONS);
      var options = $.extend({
        autoShow: false,
        autoShowDelay: 0,
        showOnce: false
      }, userOptions);

      if (options.showOnce) {
        var modal = utils.getCookie('modal-'+$this.attr('id'));
        showModal = modal === null;
      }

      if (options.autoShow && showModal) {
        setTimeout(function () {
          $this.modal('show');
        }, options.autoShowDelay);
      }
    });
  }

  $(Selector.MODAL_THEME).on(Events.HIDDEN_BS_MODAL, function (e) {
    var $this = $(e.currentTarget);
    var userOptions = $this.data(DataKey.OPTIONS);
    var options = $.extend({
      cookieExpireTime: 7200000,
      showOnce: false
    }, userOptions);
    options.showOnce && utils.setCookie('modal-'+$this.attr('id'), false, options.cookieExpireTime);
  });
});
/*-----------------------------------------------
|   Navbar Top
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selectors = {
    COLLAPSE: '.collapse',
    NAVBAR_NAV: '.navbar-nav',
    NAVBAR_TOP_COMBO: '.navbar-top-combo',
    NAVBAR_VERTICAL: '.navbar-vertical',
    NAVBAR_VERTICAL_DIVIDER: '.navbar-vertical-divider',
    NAVBAR_TOP_COMBO_COLLAPSE: '.navbar-top-combo .collapse',
    MOVEABLE_CONTENT: '[data-move-container]'
  };
  var CLASS_NAME = {
    FLEX_COLUMN: 'flex-column'
  };
  var DATA_KEYS = {
    MOVE_TARGET: 'move-target'
  };
  var $navbarTop = $(Selectors.NAVBAR_TOP_COMBO);
  var $navbarVertical = $(Selectors.NAVBAR_VERTICAL);
  var navbarTopBreakpoint = utils.getBreakpoint($navbarTop);
  var navbarVertcicalBreakpoint = utils.getBreakpoint($navbarVertical);

  var moveNavContent = function moveNavContent(width) {
    if (width < navbarTopBreakpoint) {
      var $navbarTopCollapse = $navbarTop.find(Selectors.COLLAPSE);
      var navbarTopContent = $navbarTopCollapse.html();

      if (navbarTopContent) {
        // $navbarTopCollapse.html('');
        var divider = "<div class='navbar-vertical-divider'><hr class='navbar-vertical-hr' /></div>";
        navbarTopContent = "<div data-move-container>" + divider + navbarTopContent + "</div>";
        var targetID = $navbarTop.data(DATA_KEYS.MOVE_TARGET);
        $(navbarTopContent).insertAfter(targetID);
        navbarTopBreakpoint > navbarVertcicalBreakpoint && $(Selectors.MOVEABLE_CONTENT).find(Selectors.NAVBAR_NAV).addClass(CLASS_NAME.FLEX_COLUMN);
      }
    } else {
      var $container = $(Selectors.MOVEABLE_CONTENT);
      var $navbarNav = $container.find(Selectors.NAVBAR_NAV);
      $navbarNav.hasClass(CLASS_NAME.FLEX_COLUMN) && $navbarNav.removeClass(CLASS_NAME.FLEX_COLUMN);
      $container.find(Selectors.NAVBAR_VERTICAL_DIVIDER).remove();
      var content = $container.html();
      // $container.remove();
      $(Selectors.NAVBAR_TOP_COMBO_COLLAPSE).html(content);
    }
  };

  moveNavContent(utils.$window.outerWidth());
  utils.$window.on('resize', function () {
    moveNavContent(utils.$window.outerWidth());
  });
});
/*-----------------------------------------------
|   Navbar
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $window = utils.$window;
  var navDropShadowFlag = true;
  var ClassName = {
    SHOW: 'show',
    NAVBAR_GLASS_SHADOW: 'navbar-glass-shadow',
    NAVBAR_VERTICAL_COLLAPSED: 'navbar-vertical-collapsed',
    NAVBAR_VERTICAL_COLLAPSE_HOVER: 'navbar-vertical-collapsed-hover'
  };
  var Selector = {
    HTML: 'html',
    NAVBAR: '.navbar:not(.navbar-vertical)',
    NAVBAR_VERTICAL: '.navbar-vertical',
    NAVBAR_VERTICAL_TOGGLE: '.navbar-vertical-toggle',
    NAVBAR_VERTICAL_COLLAPSE: '#navbarVerticalCollapse',
    NAVBAR_VERTICAL_CONTENT: '.navbar-vertical-content',
    NAVBAR_VERTICAL_COLLAPSED: '.navbar-vertical-collapsed',
    NAVBAR_VERTICAL_DROPDOWN_NAV: '.navbar-vertical .navbar-collapse .nav',
    NAVBAR_VERTICAL_COLLAPSED_DROPDOWN_NAV: '.navbar-vertical-collapsed .navbar-vertical .navbar-collapse .nav',
    MAIN_CONTENT: '.main .content',
    NAVBAR_TOP: '.navbar-top',
    OWL_CAROUSEL: '.owl-carousel',
    ECHART_RESPONSIVE: '[data-echart-responsive]'
  };
  var Events = {
    LOAD_SCROLL: 'load scroll',
    SCROLL: 'scroll',
    CLICK: 'click',
    RESIZE: 'resize',
    SHOW_BS_COLLAPSE: 'show.bs.collapse',
    HIDDEN_BS_COLLAPSE: 'hidden.bs.collapse'
  };
  var $html = $(Selector.HTML);
  var $navbar = $(Selector.NAVBAR);
  var $navbarVerticalCollapse = $(Selector.NAVBAR_VERTICAL_COLLAPSE);
  var $navbarVerticalContent = $(Selector.NAVBAR_VERTICAL_CONTENT);
  var navbarVertical = $(Selector.NAVBAR_VERTICAL);
  var breakPoint = utils.getBreakpoint(navbarVertical);

  var setDropShadow = function setDropShadow($elem) {
    if ($elem.scrollTop() > 0 && navDropShadowFlag) {
      $navbar.addClass(ClassName.NAVBAR_GLASS_SHADOW);
    } else {
      $navbar.removeClass(ClassName.NAVBAR_GLASS_SHADOW);
    }
  };

  $window.on(Events.LOAD_SCROLL, function () {
    return setDropShadow($window);
  });
  $navbarVerticalContent.on('scroll', function () {
    if ($window.width() < breakPoint) {
      navDropShadowFlag = true;
      setDropShadow($navbarVerticalContent);
    }
  });
  $navbarVerticalCollapse.on(Events.SHOW_BS_COLLAPSE, function () {
    if ($window.width() < breakPoint) {
      navDropShadowFlag = false;
      setDropShadow($window);
    }
  });
  $navbarVerticalCollapse.on(Events.HIDDEN_BS_COLLAPSE, function () {
    if ($navbarVerticalCollapse.hasClass(ClassName.SHOW) && $window.width() < breakPoint) {
      navDropShadowFlag = false;
    } else {
      navDropShadowFlag = true;
    }

    setDropShadow($window);
  }); // Expand or Collapse vertical navbar on mouse over and out

  $navbarVerticalCollapse.hover(function (e) {
    setTimeout(function () {
      if ($(e.currentTarget).is(':hover')) {
        $(Selector.NAVBAR_VERTICAL_COLLAPSED).addClass(ClassName.NAVBAR_VERTICAL_COLLAPSE_HOVER);
      }
    }, 100);
  }, function () {
    $(Selector.NAVBAR_VERTICAL_COLLAPSED).removeClass(ClassName.NAVBAR_VERTICAL_COLLAPSE_HOVER);
  }); // Set navbar top width from content

  var setNavbarWidth = function setNavbarWidth() {
    var contentWidth = $(Selector.MAIN_CONTENT).width() + 30;
    $(Selector.NAVBAR_TOP).outerWidth(contentWidth);
  }; // Toggle navbar vertical collapse on click


  $(document).on(Events.CLICK, Selector.NAVBAR_VERTICAL_TOGGLE, function () {
    // Set collapse state on localStorage
    var isNavbarVerticalCollapsed = JSON.parse(localStorage.getItem('isNavbarVerticalCollapsed'));
    localStorage.setItem('isNavbarVerticalCollapsed', !isNavbarVerticalCollapsed); // Toggle class

    $html.toggleClass(ClassName.NAVBAR_VERTICAL_COLLAPSED); // Set navbar top width

    setNavbarWidth(); // Refresh owlCarousel

    var $owlCarousel = $(Selector.OWL_CAROUSEL);

    if ($owlCarousel.length) {
      $owlCarousel.owlCarousel('refresh');
    } // Refresh Echarts


    var $echarts = document.querySelectorAll(Selector.ECHART_RESPONSIVE);

    if ($echarts.length) {
      $.each($echarts, function (item, value) {
        if ($(value).data('echart-responsive')) {
          window.echarts.init(value).resize();
        }
      });
    }
  }); // Set navbar top width on window resize

  $window.on(Events.RESIZE, function () {
    setNavbarWidth();
  });
});


/*-----------------------------------------------
|   Navbar Top Combo
-----------------------------------------------*/

// document.addEventListener("turbolinks:load", function() {
//   const Selectors = {
//     COLLAPSE: ".collapse",
//     NAVBAR_NAV: ".navbar-nav",
//     NAVBAR_TOP_COMBO: ".navbar-top-combo",
//     NAVBAR_VERTICAL: ".navbar-vertical",
//     NAVBAR_VERTICAL_DIVIDER: ".navbar-vertical-divider",
//     NAVBAR_TOP_COMBO_COLLAPSE: ".navbar-top-combo .collapse",
//     MOVEABLE_CONTENT: "[data-move-container]",
//   };
//   const CLASS_NAME = {
//     FLEX_COLUMN: "flex-column",
//   };
//   const DATA_KEYS = {
//     MOVE_TARGET: "move-target",
//   };
//   const $navbarTop = $(Selectors.NAVBAR_TOP_COMBO);
//   const $navbarVertical = $(Selectors.NAVBAR_VERTICAL);
//   const navbarTopBreakpoint = utils.getBreakpoint($navbarTop);
//   const navbarVertcicalBreakpoint = utils.getBreakpoint($navbarVertical);

//   const moveNavContent = (width) => {
//     if (width < navbarTopBreakpoint) {
//       const $navbarTopCollapse = $navbarTop.find(Selectors.COLLAPSE);
//       let navbarTopContent = $navbarTopCollapse.html();
//       if (navbarTopContent) {
//         $navbarTopCollapse.html("");
//         const divider =
//           "<div class='navbar-vertical-divider'><hr class='navbar-vertical-hr' /></div>";
//         navbarTopContent = `<div data-move-container>${divider}${navbarTopContent}</div>`;
//         const targetID = $navbarTop.data(DATA_KEYS.MOVE_TARGET);
//         $(navbarTopContent).insertAfter(targetID);

//         navbarTopBreakpoint > navbarVertcicalBreakpoint &&
//           $(Selectors.MOVEABLE_CONTENT)
//             .find(Selectors.NAVBAR_NAV)
//             .addClass(CLASS_NAME.FLEX_COLUMN);
//       }
//     } else {
//       const $container = $(Selectors.MOVEABLE_CONTENT);
//       const $navbarNav = $container.find(Selectors.NAVBAR_NAV);

//       $navbarNav.hasClass(CLASS_NAME.FLEX_COLUMN) &&
//         $navbarNav.removeClass(CLASS_NAME.FLEX_COLUMN);

//       $container.find(Selectors.NAVBAR_VERTICAL_DIVIDER).remove();
//       const content = $container.html();
//       $container.remove();
//       $(Selectors.NAVBAR_TOP_COMBO_COLLAPSE).html(content);
//     }
//   };
//   moveNavContent(utils.$window.outerWidth());
//   utils.$window.on("resize", () => {
//     moveNavContent(utils.$window.outerWidth());
//   });
// });
/*-----------------------------------------------
|   Cookie Notice
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selector = {
    NOTICE: '.notice'
  };
  var DataKeys = {
    OPTIONS: 'options'
  };
  var CookieNames = {
    COOKIE_NOTICE: 'cookieNotice'
  };
  var Events = {
    HIDDEN_BS_TOAST: 'hidden.bs.toast'
  };
  var $notices = $(Selector.NOTICE);
  var defaultOptions = {
    autoShow: false,
    autoShowDelay: 0,
    showOnce: false,
    cookieExpireTime: 3600000
  };
  $notices.each(function (index, value) {
    var $this = $(value);
    var options = $.extend(defaultOptions, $this.data(DataKeys.OPTIONS));
    var cookieNotice;

    if (options.showOnce) {
      cookieNotice = utils.getCookie(CookieNames.COOKIE_NOTICE);
    }

    if (options.autoShow && cookieNotice === null) {
      setTimeout(function () {
        return $this.toast('show');
      }, options.autoShowDelay);
    }
  });
  $(Selector.NOTICE).on(Events.HIDDEN_BS_TOAST, function (e) {
    var $this = $(e.currentTarget);
    var options = $.extend(defaultOptions, $this.data(DataKeys.OPTIONS));
    options.showOnce && utils.setCookie(CookieNames.COOKIE_NOTICE, false, options.cookieExpireTime);
  });
  utils.$document.on('click', "[data-toggle='notice']", function (e) {
    e.preventDefault();
    var $this = $(e.currentTarget);
    var $target = $($this.attr('href'));

    if ($target.hasClass('show')) {
      $target.toast('hide');
    } else {
      $target.toast('show');
    }
  });
});
/*-----------------------------------------------
|   Owl Carousel
-----------------------------------------------*/


document.addEventListener("turbolinks:load", function() {
  var $carousel = $('.owl-carousel');
  if ($carousel.length) {
    var Selector = {
      ALL_TIMELINE: '*[data-zanim-timeline]',
      ACTIVE_ITEM: '.owl-item.active'
    };
    var owlZanim = {
      zanimTimeline: function zanimTimeline($el) {
        return $el.find(Selector.ALL_TIMELINE);
      },
      play: function play($el) {
        if (this.zanimTimeline($el).length === 0) return;
        $el.find(Selector.ACTIVE_ITEM + " > " + Selector.ALL_TIMELINE).zanimation(function (animation) {
          animation.play();
        });
      },
      kill: function kill($el) {
        if (this.zanimTimeline($el).length === 0) return;
        this.zanimTimeline($el).zanimation(function (animation) {
          animation.kill();
        });
      }
    };
    $carousel.each(function (index, value) {
      var $this = $(value);
      var options = $this.data('options') || {};
      utils.isRTL() && (options.rtl = true);
      options.navText || (options.navText = ['<span class="fas fa-angle-left"></span>', '<span class="fas fa-angle-right"></span>']);
      options.touchDrag = true;
      $this.owlCarousel($.extend(options || {}, {
        onInitialized: function onInitialized(event) {
          owlZanim.play($(event.target));
        },
        onTranslate: function onTranslate(event) {
          owlZanim.kill($(event.target));
        },
        onTranslated: function onTranslated(event) {
          owlZanim.play($(event.target));
        }
      }));
    });
  }

  var $controllers = $('[data-owl-carousel-controller]');

  if ($controllers.length) {
    $controllers.each(function (index, value) {
      var $this = $(value);
      var $thumbs = $($this.data('owl-carousel-controller'));
      $thumbs.find('.owl-item:first-child').addClass('current');
      $thumbs.on('click', '.item', function (e) {
        var thumbIndex = $(e.target).parents('.owl-item').index();
        $('.owl-item').removeClass('current');
        $(e.target).parents('.owl-item').addClass('current');
        $this.trigger('to.owl.carousel', thumbIndex, 500);
      });
      $this.on('changed.owl.carousel', function (e) {
        var itemIndex = e.item.index;
        var item = itemIndex + 1;
        $('.owl-item').removeClass('current');
        $thumbs.find(".owl-item:nth-child(" + item + ")").addClass('current');
        $thumbs.trigger('to.owl.carousel', itemIndex, 500);
      });
    });
  }
});
/*-----------------------------------------------
|   Perfect Scrollbar
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  if (window.is.ie() || window.is.edge()) {
    var scrollbars = document.querySelectorAll('.perfect-scrollbar');

    if (scrollbars.length) {
      $.each(scrollbars, function (item, value) {
        var $this = $(value);
        var userOptions = $this.data('options');
        var options = $.extend({
          wheelPropagation: true,
          suppressScrollX: true,
          suppressScrollY: false
        }, userOptions);
        var ps = new PerfectScrollbar(value, options);
        ps.update();
      });
    }
  }
});
/*-----------------------------------------------
|   Inline Player [plyr]
-----------------------------------------------*/

/*
  global Plyr
*/

document.addEventListener("turbolinks:load", function() {
  var $players = $('.player');

  if ($players.length) {
    $players.each(function (index, value) {
      return new Plyr($(value), {
        captions: {
          active: true
        }
      });
    });
  }

  return false;
});
/*
 global ProgressBar
*/

document.addEventListener("turbolinks:load", function() {
  var merge = window._.merge; // progressbar.js@1.0.0 version is used
  // Docs: http://progressbarjs.readthedocs.org/en/1.0.0/

  /*-----------------------------------------------
  |   Progress Circle
  -----------------------------------------------*/

  var progresCircle = $('.progress-circle');

  if (progresCircle.length) {
    progresCircle.each(function (index, value) {
      var $this = $(value);
      var userOptions = $this.data('options');
      var defaultOptions = {
        strokeWidth: 2,
        trailWidth: 2,
        trailColor: utils.grays['200'],
        easing: 'easeInOut',
        duration: 3000,
        svgStyle: {
          'stroke-linecap': 'round',
          display: 'block',
          width: '100%'
        },
        text: {
          autoStyleContainer: false
        },
        // Set default step function for all animate calls
        step: function step(state, circle) {
          // Change stroke color during progress
          // circle.path.setAttribute('stroke', state.color);
          // Change stroke width during progress
          // circle.path.setAttribute('stroke-width', state.width);
          var percentage = Math.round(circle.value() * 100);
          circle.setText("<span class='value'>" + percentage + "<b>%</b></span> <span>" + (userOptions.text || '') + "</span>");
        }
      }; // Assign default color for IE

      var color = userOptions.color && userOptions.color.includes('url');

      if (window.is.ie() && color) {
        userOptions.color = utils.colors.primary;
      }

      var options = merge(defaultOptions, userOptions);
      var bar = new ProgressBar.Circle(value, options);
      var linearGradient = "<defs>\n          <linearGradient id=\"gradient\" x1=\"0%\" y1=\"0%\" x2=\"100%\" y2=\"0%\" gradientUnits=\"userSpaceOnUse\">\n            <stop offset=\"0%\" stop-color='#1970e2' />\n            <stop offset=\"100%\" stop-color='#4695ff' />\n          </linearGradient>\n        </defs>"; // Disable gradient color in IE

      !window.is.ie() && bar.svg.insertAdjacentHTML('beforeEnd', linearGradient);
      var playProgressTriggered = false;

      var progressCircleAnimation = function progressCircleAnimation() {
        if (!playProgressTriggered) {
          if (utils.isScrolledIntoView(value) || utils.nua.match(/puppeteer/i)) {
            bar.animate(options.progress / 100);
            playProgressTriggered = true;
          }
        }

        return playProgressTriggered;
      };

      progressCircleAnimation();
      utils.$window.scroll(function () {
        progressCircleAnimation();
      });
    });
  }
  /*-----------------------------------------------
  |   Progress Line
  -----------------------------------------------*/


  var progressLine = $('.progress-line');

  if (progressLine.length) {
    progressLine.each(function (index, value) {
      var $this = $(value);
      var options = $this.data('options');
      var bar = new ProgressBar.Line(value, {
        strokeWidth: 1,
        easing: 'easeInOut',
        duration: 3000,
        color: '#333',
        trailColor: '#eee',
        trailWidth: 1,
        svgStyle: {
          width: '100%',
          height: '0.25rem',
          'stroke-linecap': 'round',
          'border-radius': '0.125rem'
        },
        text: {
          style: {
            transform: null
          },
          autoStyleContainer: false
        },
        from: {
          color: '#aaa'
        },
        to: {
          color: '#111'
        },
        step: function step(state, line) {
          line.setText("<span class='value'>" + Math.round(line.value() * 100) + "<b>%</b></span> <span>" + options.text + "</span>");
        }
      });
      var playProgressTriggered = false;

      var progressLineAnimation = function progressLineAnimation() {
        if (!playProgressTriggered) {
          if (utils.isScrolledIntoView(value) || utils.nua.match(/puppeteer/i)) {
            bar.animate(options.progress / 100);
            playProgressTriggered = true;
          }
        }

        return playProgressTriggered;
      };

      progressLineAnimation();
      utils.$window.scroll(function () {
        progressLineAnimation();
      });
    });
  }
});
/*-----------------------------------------------
|   Raty
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var ratings = $('.raty');

  if (ratings.length) {
    ratings.each(function (index, value) {
      var $this = $(value);
      var options = $.extend({
        starHalf: 'star-half-png text-warning',
        starOff: 'star-off-png text-300',
        starOn: 'star-on-png text-warning',
        starType: 'span',
        size: 30
      }, $this.data('options'));
      $(value).raty(options);
    });
  }
});

/*
  global Stickyfill
*/

/*-----------------------------------------------
|   Sticky fill
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  Stickyfill.add($('.sticky-top'));
  Stickyfill.add($('.sticky-bottom'));
});
/*-----------------------------------------------
|   Sticky Kit
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  if (window.is.ie()) {
    var stickyKits = $('.sticky-kit');

    if (stickyKits.length) {
      stickyKits.each(function (index, value) {
        var $this = $(value);

        var options = _objectSpread({}, $this.data('options'));

        $this.stick_in_parent(options);
      });
    }
  }
});
/*-----------------------------------------------
|   Tabs
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $fancyTabs = $('.fancy-tab');

  if ($fancyTabs.length) {
    var Selector = {
      TAB_BAR: '.nav-bar',
      TAB_BAR_ITEM: '.nav-bar-item',
      TAB_CONTENTS: '.tab-contents'
    };
    var ClassName = {
      ACTIVE: 'active',
      TRANSITION_REVERSE: 'transition-reverse',
      TAB_INDICATOR: 'tab-indicator'
    };
    /*-----------------------------------------------
    |   Function for active tab indicator change
    -----------------------------------------------*/

    var updateIncicator = function updateIncicator($indicator, $tabs, $tabnavCurrentItem) {
      var _$tabnavCurrentItem$p = $tabnavCurrentItem.position(),
          left = _$tabnavCurrentItem$p.left;

      var right = $tabs.children(Selector.TAB_BAR).outerWidth() - (left + $tabnavCurrentItem.outerWidth());
      $indicator.css({
        left: left,
        right: right
      });
    };

    $fancyTabs.each(function (index, value) {
      var $tabs = $(value);
      var $navBar = $tabs.children(Selector.TAB_BAR);
      var $tabnavCurrentItem = $navBar.children(Selector.TAB_BAR_ITEM + "." + ClassName.ACTIVE);
      $navBar.append("\n        <div class=" + ClassName.TAB_INDICATOR + "></div>\n      ");
      var $indicator = $navBar.children("." + ClassName.TAB_INDICATOR);
      var $preIndex = $tabnavCurrentItem.index();
      updateIncicator($indicator, $tabs, $tabnavCurrentItem);
      $navBar.children(Selector.TAB_BAR_ITEM).click(function (e) {
        $tabnavCurrentItem = $(e.currentTarget);
        var $currentIndex = $tabnavCurrentItem.index();
        var $tabContent = $tabs.children(Selector.TAB_CONTENTS).children().eq($currentIndex);
        $tabnavCurrentItem.siblings().removeClass(ClassName.ACTIVE);
        $tabnavCurrentItem.addClass(ClassName.ACTIVE);
        $tabContent.siblings().removeClass(ClassName.ACTIVE);
        $tabContent.addClass(ClassName.ACTIVE);
        /*-----------------------------------------------
        |   Indicator Transition
        -----------------------------------------------*/

        updateIncicator($indicator, $tabs, $tabnavCurrentItem);

        if ($currentIndex - $preIndex <= 0) {
          $indicator.addClass(ClassName.TRANSITION_REVERSE);
        } else {
          $indicator.removeClass(ClassName.TRANSITION_REVERSE);
        }

        $preIndex = $currentIndex;
      });
      utils.$window.on('resize', function () {
        updateIncicator($indicator, $tabs, $tabnavCurrentItem);
      });
    });
  }
  /*-----------------------------------------------
  |   Product Review Tab
  -----------------------------------------------*/


  var $review = $('[data-tab-target]');
  $review.click(function (e) {
    var $this = $(e.currentTarget);
    var $reviewTab = $($this.data('tab-target'));
    $reviewTab.trigger('click');
  });
});
/*-----------------------------------------------
|   TINYMCE
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var tinymces = $('.tinymce');

  if (tinymces.length) {
    window.tinymce.init({
      selector: '.tinymce',
      height: '50vh',
      menubar: false,
      skin: utils.settings.tinymce.theme,
      content_style: ".mce-content-body { color: " + utils.grays.black + " }",
      mobile: {
        theme: 'mobile',
        toolbar: ['undo', 'bold']
      },
      statusbar: false,
      plugins: 'link,image,lists,table,media',
      toolbar: 'styleselect | bold italic link bullist numlist image blockquote table media undo redo'
    });
  }
});
/*-----------------------------------------------
|   Toast [bootstrap 4]
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  $('.toast').toast();
});
/*-----------------------------------------------
|   Toastr
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var $notifications = $('[data-notification]');
  $notifications.each(function (index, value) {
    var _window4 = window,
        toastr = _window4.toastr;
    var $this = $(value);
    var config = $this.data('notification');
    var defaultOptions = {
      closeButton: true,
      newestOnTop: false,
      positionClass: 'toast-bottom-right'
    };
    $this.on('click', function () {
      var type = config.type,
          title = config.title,
          message = config.message;
      var mergedOptions = $.extend(defaultOptions, config);
      toastr.options.positionClass !== mergedOptions.positionClass && toastr.remove();
      toastr.options = mergedOptions;

      switch (type) {
        case 'success':
          toastr.success(message, title);
          break;

        case 'warning':
          toastr.warning(message, title);
          break;

        case 'error':
          toastr.error(message, title);
          break;

        default:
          toastr.info(message, title);
          break;
      }
    });
  });
});
/*-----------------------------------------------
|   Tootltip [bootstrap 4]
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  // https://getbootstrap.com/docs/4.0/components/tooltips/#example-enable-tooltips-everywhere
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
});
/*-----------------------------------------------
|   Typed Text
-----------------------------------------------*/

/*
  global Typed
 */

document.addEventListener("turbolinks:load", function() {
  var typedText = $('.typed-text');

  if (typedText.length) {
    typedText.each(function (index, value) {
      return new Typed(value, {
        strings: $(value).data('typed-text'),
        typeSpeed: 100,
        loop: true,
        backDelay: 1500
      });
    });
  }
});

document.addEventListener("turbolinks:load", function() {
  $('[data-fancybox="gallery"]').fancybox({
    // Options will go here
    arrows: true,
  });
});
/*-----------------------------------------------
|   YTPlayer
-----------------------------------------------*/

// document.addEventListener("turbolinks:load", function() {
//   var Selector = {
//     BG_YOUTUBE: '.bg-youtube',
//     BG_HOLDER: '.bg-holder'
//   };
//   var DATA_KEY = {
//     PROPERTY: 'property'
//   };
//   var $youtubeBackground = $(Selector.BG_YOUTUBE);

//   if ($youtubeBackground.length) {
//     $youtubeBackground.each(function (index, value) {
//       var $this = $(value);
//       $this.data(DATA_KEY.PROPERTY, $.extend($this.data(DATA_KEY.PROPERTY), {
//         showControls: false,
//         loop: true,
//         autoPlay: true,
//         mute: true,
//         containment: $this.parent(Selector.BG_HOLDER)
//       }));
//       $this.YTPlayer();
//     });
//   }
// });